import cookie from 'cookie';
import * as React from 'react';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import { useState, useEffect } from 'react';
import TestResult from "../../components/test-result";
import JSCookieTests from "../../components/js-cookie-test";


const TEST_COOKIE_DOCUMENT = 'test-cookie-document';
const TEST_COOKIE_STORE = 'test-cookie-store';
const TEST_COOKIE_HEADER = 'test-cookie-header';

const CookieSandboxingPage = ({ location }) => {
  const [tests, setTests] = useState({
    'domainA_1st_party': { value: '', passed: undefined },
    'domainA_3rd_party': { value: '', passed: undefined },
  });

  const [jsTests, setJSTests] = useState({ host: '', tests: [], check: () => [] });

  const queryParams = new URLSearchParams(location.search);

  const moveToDomain = (domain, step, value) => {
    if (step) {
      queryParams.set('step', step)
    }
    if (value) {
      queryParams.set('value', value)
    }
    window.location.href = `https://${domain}/cookies/sandboxing?${queryParams.toString()}`;
  }

  const storeCookies = async (domain, value) => {
    await fetch(`https://${domain}/backend/set-cookie?name=test&value=${value}&exp_sec=7200`, {
      credentials: 'include',
    });

    const result = new Promise(resolve => {
      setJSTests({
        host: domain,
        tests: [
          {
            name: TEST_COOKIE_DOCUMENT,
            cookie: TEST_COOKIE_DOCUMENT,
            value: value,
            props: { api: 'document.cookie', exp_attr: 'max-age', exp_sec: 40 }
          },
          {
            name: TEST_COOKIE_STORE,
            cookie: TEST_COOKIE_STORE,
            value: value,
            props: { api: 'CookieStore', exp_sec: 40 }
          },
        ],
        check: (cookies, tests) => {
          const map = tests.map((t) => (cookies[t.cookie] === t.value));
          resolve(map);
          return map;
        }
      })
    });
    await result;
  }

  const readCookies = async (domain) => {
    const r = await fetch(`https://${domain}/backend/get-cookie`, {
      credentials: 'include',
    })
    let httpCookie = {
      key: TEST_COOKIE_HEADER, value: cookie.parse((await r.json())['cookies'])['test']
    };

    const resultPromise = new Promise(resolve => {
      setJSTests({
        host: domain,
        tests: [],
        check: (cookies, tests) => {
          const map = [TEST_COOKIE_DOCUMENT, TEST_COOKIE_STORE].map((id) => {
            return { key: id, value: cookies[id] }
          });
          resolve(map);
          return map;
        }
      })
    });
    const result = await resultPromise;
    return [...result, httpCookie]
  }

  const runTestNextStep = async (currentStep) => {

    let newState = { ...tests };

    if (currentStep === 1) {
      if (window.location.hostname !== process.env.GATSBY_MAIN_HOST) {
        moveToDomain(process.env.GATSBY_MAIN_HOST, 1)
        return
      }

      const value = new Date().getTime().toString()
      await storeCookies(process.env.GATSBY_MAIN_HOST, value)
      await storeCookies(process.env.GATSBY_TERTIARY_HOST, value)

      const firstPartyCookie = await readCookies(process.env.GATSBY_MAIN_HOST)
      const thirdPartyCookies = await readCookies(process.env.GATSBY_TERTIARY_HOST)


      newState['domainA_1st_party'].passed = true;
      newState['domainA_1st_party'].value = JSON.stringify(firstPartyCookie);
      if (firstPartyCookie.filter(c => c.value !== value).length > 0) {
        newState['domainA_1st_party'].passed = false;
        newState['domainA_3rd_party'].passed = false;
      } else if (thirdPartyCookies.filter(c => c.value !== value).length > 0) {
        newState['domainA_3rd_party'].passed = false;
        newState['domainA_3rd_party'].value = JSON.stringify(firstPartyCookie);
      } else {
        moveToDomain(process.env.GATSBY_SECONDARY_HOST, 2, value)
      }
    } else if (currentStep === 2) {
      newState['domainA_1st_party'].passed = true;
      const value = queryParams.get("value")
      const thirdPartyCookies = await readCookies(process.env.GATSBY_TERTIARY_HOST, value)

      if (thirdPartyCookies.filter(c => c.value === value).length > 0) {
        newState['domainA_3rd_party'].passed = false;
        newState['domainA_3rd_party'].value = JSON.stringify(thirdPartyCookies);
        moveToDomain(process.env.GATSBY_MAIN_HOST, 4)
      } else {
        newState['domainA_3rd_party'].passed = true;
        newState['domainA_3rd_party'].value = JSON.stringify(thirdPartyCookies);
        moveToDomain(process.env.GATSBY_MAIN_HOST, 3)
      }
    } else if (currentStep === 3) {
      newState['domainA_1st_party'].passed = true;
      newState['domainA_1st_party'].value = "cookie stored";
      newState['domainA_3rd_party'].passed = true;
      newState['domainA_3rd_party'].value = "cookie sandboxed";
    } else if (currentStep === 4) {
      newState['domainA_1st_party'].passed = true;
      newState['domainA_1st_party'].value = "stored";
      newState['domainA_3rd_party'].passed = false;
      newState['domainA_3rd_party'].value = "cookie not sandboxed";
    }
    setTests(newState);
  }

  const resetAndStartAgain = () => {
    return runTestNextStep(1)
  }

  useEffect(() => {
    const step = Number(queryParams.get("step") || 0)
    if (step > 0) {
      return runTestNextStep(step)
    }
  }, []);

  return (
    <Layout>
      <h1>Set cookies</h1>

      <p>
        This page will set some cookies to check their visibility from other domains. The page may reload multiple
        times.
      </p>

      <button
        className="bg-emerald-600 text-white w-full mb-10 mt-5"
        onClick={() => {
          return resetAndStartAgain();
        }}
      >
        Run test
      </button>

      <table className="table-fixed border-collapse border-2 text-left w-full mb-10">
        <thead className="bg-slate-200">
        <tr>
          <th className="border border-slate-300 font-semibold p-4">Cookie</th>
          <th className="border border-slate-300 font-semibold p-4">Value</th>
          <th className="border border-slate-300 font-semibold p-4">Test result</th>
        </tr>
        </thead>
        <tbody>
        {
          Object.keys(tests).map((test) => (
            <tr className="border-2" key={test}>
              <td className="border p-4">
                <code>{test}</code>
              </td>
              <td className="border p-4">
                {
                  tests[test].value ?
                    <code>{tests[test].value}</code> :
                    <small><i>(empty)</i></small>
                }
              </td>
              <TestResult result={tests[test].passed} colorStyle="bg">
                <td className="border p-4 text-white">
                  {tests[test].passed === undefined ? 'In progress' : tests[test].passed ? 'Passed' : 'Failed'}
                </td>
              </TestResult>

            </tr>
          ))
        }
        </tbody>
      </table>
      <div style={{ visibility: 'hidden' }}>
        <JSCookieTests host={jsTests.host} tests={jsTests.tests} check={jsTests.check}/>
      </div>
      <div className=" flex flex-row justify-between">
        <a href={`https://${process.env.GATSBY_MAIN_HOST}/`}>
          Go back
        </a>
      </div>

    </Layout>
  );
}

export const Head = () => <Seo title=" Set cookies"/>
export default CookieSandboxingPage;
